html,
body {
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
}


#root {
  height: 100%;
}


.swiper {
  width: 350px;
  height: 400px;
}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet { width: 12px; height: 12px; background: #D9D9D9; opacity: 1; }
.swiper-pagination-bullet-active { width: 25px; transition: width .5s; border-radius: 10px; background: #ED6D17; border: 1px solid transparent; }